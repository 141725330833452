import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import gsap from "gsap";
import Link from ".";
import { windowWidth } from "../../utils/windowDimensions";

const TransitionOUT = ({ exit: { length }, node }) => {
  gsap.timeline().fromTo(
    node,
    length,
    {
      opacity: 1
    },
    {
      opacity: 0
    }
  );
};

const TransitionIN = ({ entry: { length }, node }) => {
  const target = node.querySelector(".js-transition-link-target");
  gsap.timeline().fromTo(
    target,
    length,
    {
      y: "25vh"
    },
    {
      y: 0
    }
  );
  gsap.timeline().fromTo(
    node,
    1.5,
    {
      opacity: 0
    },
    {
      opacity: 1
    }
  );
  window.scrollTo(0, 0);
};

const LinkWorkTo = ({ ...props }) => {
  if (windowWidth < 1024) {
    return <Link {...props}>{props.children}</Link>;
  } else {
    return (
      <TransitionLink
        preventScrollJump
        exit={{
          length: 0.5,
          zIndex: 999,
          trigger: ({ exit, node }) => TransitionOUT({ exit, node })
        }}
        entry={{
          length: 0.75,
          delay: 0.75,
          trigger: ({ entry, node }) => TransitionIN({ entry, node })
        }}
        {...props}
      >
        {props.children}
      </TransitionLink>
    );
  }
};

export default LinkWorkTo;
