import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SEO from "../SEO";
import Header from "../Header";
import setWindowHeightProperty from "../../utils/setWindowHeightProperty";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const Layout = ({
  children,
  seoTitle,
  seoDescription,
  location,
  hasLightTheme,
  hasMenuIcon
}) => {
  useEffect(() => {
    setWindowHeightProperty();
  }, []);

  const isArchitectsAucklandPage =
    location.pathname === "/architects-auckland/";

  return (
    <div className="font-sans font-light">
      <SEO title={seoTitle} description={seoDescription} location={location} />
      <Header
        lightTheme={hasLightTheme}
        menuIcon={hasMenuIcon}
        location={location}
      />
      {isArchitectsAucklandPage && (
        <Helmet>
          <script>{`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:5111627,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}</script>
        </Helmet>
      )}
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  location: PropTypes.object.isRequired,
  hasLightTheme: PropTypes.bool,
  hasMenuIcon: PropTypes.bool
};

export default Layout;
