import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";

function Footer() {
  const {
    prismicGlobal: { data }
  } = useStaticQuery(FOOTER_ITEMS_QUERY);

  const footerItems = [
    {
      title: data.footer_column_title__1,
      url: data.footer_column_link__1.url,
      links: data.column_subitems__1.map(item => ({
        title: item.subitem_title,
        url: item.subitem_link.url,
        id: item.item_id
      }))
    },
    {
      title: data.footer_column_title__2,
      url: data.footer_column_link__2.url,
      links: data.column_subitems__2.map(item => ({
        title: item.subitem_title,
        url: item.subitem_link.url
      }))
    },
    {
      title: data.footer_column_title__3,
      url: data.footer_column_link__3.url,
      links: data.column_subitems__3.map(item => ({
        title: item.subitem_title,
        url: item.subitem_link.url
      }))
    }
  ];

  return (
    <div className="bg-eerie-black text-white px-ogs">
      <div className="md:grid md:grid-cols-2 lg:grid-cols-3 pt-16 text-xs gap-x-10">
        <div className="w-full">
          <Link to="/">
            <Image
              placeholderStyle={{ display: "none" }}
              fluid={data.logo?.fluid}
              className="w-32 md:w-48 lg:w-56 fill-current"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="md:flex justify-between lg:justify-start gap-12 lg:gap-0 lg:space-x-16 md:col-start-1 lg:col-start-2">
          {footerItems.map((item, index) => (
            <div key={item.title} className="flex flex-col mt-10 lg:mt-0">
              <h3 className="font-bold">
                <Link to={item.url}>{item.title}</Link>
              </h3>
              <ul className="mt-6 space-y-3">
                {item.links.map(
                  link =>
                    link.url && (
                      <li className="font-light" key={link.title}>
                        {link.id ? (
                          <Link to={`${link.url}#${link.id}`}>
                            {link.title}
                          </Link>
                        ) : (
                          <Link to={link.url}>{link.title}</Link>
                        )}
                      </li>
                    )
                )}
                {index === 2 && (
                  <>
                    <li className="font-light">
                      <a href={`mailto:${data.email_address}`}>Email</a>
                    </li>
                    <li className="font-light">
                      <a href={`tel:${data.phone_number}`}>Phone</a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          ))}
        </div>
        {data.award_text && (
          <p className="ml-auto text-sm lg:col-start-3 mt-10 lg:mt-0">
            {data.award_text}
          </p>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between pt-24 pb-12 text-xs">
        <p>© O’Neil Architecture {data.year}</p>
        <ul className="flex flex-wrap justify-between mt-4 md:mt-0 gap-y-3 md:space-x-8">
          {data.secondary_items.map(
            (item, index) =>
              item.item_link?.url && (
                <li className="inline-block" key={index}>
                  <Link className="link--white" to={item.item_link.url}>
                    {item.item_title}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
}

export default Footer;

const FOOTER_ITEMS_QUERY = graphql`
  query FooterItems {
    prismicGlobal {
      data {
        logo {
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1280]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
        footer_column_title__1
        footer_column_title__2
        footer_column_title__3
        column_subitems__1 {
          item_id
          subitem_link {
            url
          }
          subitem_title
        }
        column_subitems__2 {
          subitem_title
          subitem_link {
            url
          }
        }
        column_subitems__3 {
          subitem_title
          subitem_link {
            url
          }
        }
        footer_column_link__1 {
          url
        }
        footer_column_link__2 {
          url
        }
        footer_column_link__3 {
          url
        }
        award_text
        year
        email_address
        phone_number
        secondary_items {
          item_link {
            url
          }
          item_title
        }
      }
    }
  }
`;
