import { isMobile } from "react-device-detect";

const setWindowHeightProperty = () => {
  document.documentElement.style.setProperty(
    "--window-height",
    isMobile ? `${window.innerHeight}px` : "100vh"
  );
};

export default setWindowHeightProperty;
